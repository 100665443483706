import React from "react";
import Content from '../views/LandingDriver/Main';

export default ({location}) => <Content location={location} seo={{
  title: "Bil-app til enhver bilist",
  lang: 'da-DK',
  description: "En bil-app der giver et bedre overblik over bilen. Funktioner som automatisk kørebog, overblik over køretøjets tilstand samt kommunikation med værkstedet.",
  meta: [{
    name: 'keywords',
    content: 'Bilister',
  },
  {
    name: 'robots',
    content: 'noindex',
  }]
}}/>