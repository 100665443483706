import React from 'react';
import Header from '../../../components/Header/navmap/landingDriver';
import Layout from "../../../components/Layout"
import SEO from "../../../components/seo";
import sitemap from '../../../data/sitemap';
import i18n from '../../../locale';
import Hero from './Hero';
import Pitch from './Pitch';
import Overview from './Overview';
import Advantages from './Advantages';
import Compare from './Compare';
import GettingStarted from './GettingStarted';
import Pricing from './Pricing';
import FAQ from './FAQ';

export default ({ seo, location }) => (
  <Layout overlay 
    page={sitemap.landingDriver._}
    header={Header}
    isLanding={'drive'}
    url='_url:landing-driver'
    homeLink={i18n('_url:landing-driver')}>
    <SEO {...seo} location={location}/>
    <Hero/>
    <Pitch/>
    <Overview/>
    <Advantages/>
    <Compare/>
    <Pricing/>
    <GettingStarted/>
    <FAQ/>
  </Layout>
);